import { render, staticRenderFns } from "./Item.vue?vue&type=template&id=1316b953&scoped=true&"
import script from "./Item.vue?vue&type=script&lang=js&"
export * from "./Item.vue?vue&type=script&lang=js&"
import style0 from "./Item.vue?vue&type=style&index=0&id=1316b953&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1316b953",
  null
  
)

export default component.exports