var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image-upload d-flex justify-content-between"},[_c('div'),_c('div',[_c('input',{ref:"file",staticClass:"custom-file-input",style:([
        this.url === null
          ? {
              backgroundImage: 'url(' + this.image + ')',
            }
          : {
              backgroundImage: 'url(' + this.url + ')',
            } ]),attrs:{"accept":"image/png, image/gif, image/jpeg","type":"file"},on:{"change":_vm.onFileChange}}),(this.url === null)?_c('p',{staticClass:"text-center"},[_vm._v("Select image to upload")]):_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.fileName))])]),_c('div')])}
var staticRenderFns = []

export { render, staticRenderFns }